var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm.profileData ? _c('b-row', {
    staticClass: "mb-5"
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h5', {
    staticClass: "heading"
  }, [_vm._v("My Orders")])]), _c('div')]), _c('div', {
    staticClass: "promotional_akshay_tab_div text-center"
  }, [_c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Product Order",
      "active": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-list-group', {
    staticClass: "list-group-order"
  }, [_vm.$options.components['profile-order'] ? _vm._l(_vm.orderList, function (item, index) {
    return _c('profile-order', {
      key: index,
      attrs: {
        "orderItem": item
      }
    });
  }) : _vm._e()], 2)], 1), !_vm.orderList.length ? _c('div', [_c('b-img', {
    staticClass: "mr-3 w-50 mt-3",
    attrs: {
      "center": "",
      "src": "product.PNG"
    }
  }), _c('h5', {
    staticClass: "text-center mt-3",
    attrs: {
      "head-variant": "light"
    }
  }, [_vm._v(" You Have No Orders! ")]), _c('h5', {
    staticClass: "text-center color mt-3",
    attrs: {
      "head-variant": "light"
    }
  }, [_vm._v(" Looks like you haven't placed any Order yet. ")]), _c('b-col', {
    staticClass: "text-center pb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary btn-lg",
    on: {
      "click": _vm.startTrips
    }
  }, [_vm._v(" Start Shoping ")])])], 1) : _vm._e()]), _c('b-tab', {
    attrs: {
      "title": "Service Order"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mb-1 mt-1"
  }, [_c('b-list-group', {
    staticClass: "list-group-order"
  }, [_vm.$options.components['profile-order'] ? _vm._l(_vm.serviceOrderList, function (item, index) {
    return _c('profile-order', {
      key: index,
      attrs: {
        "orderItem": item
      }
    });
  }) : _vm._e()], 2)], 1), !_vm.serviceOrderList.length ? _c('div', [_c('b-img', {
    staticClass: "mr-3 w-50 mt-3",
    attrs: {
      "center": "",
      "src": "service.PNG"
    }
  }), _c('h5', {
    staticClass: "text-center mt-3",
    attrs: {
      "head-variant": "light"
    }
  }, [_vm._v(" You Have No Service Orders! ")]), _c('h5', {
    staticClass: "text-center color mt-3",
    attrs: {
      "head-variant": "light"
    }
  }, [_vm._v(" Looks like you haven't booked any In Service Orders yet. ")]), _c('b-col', {
    staticClass: "text-center pb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary btn-lg",
    on: {
      "click": _vm.startTrips
    }
  }, [_vm._v(" Start Booking ")])])], 1) : _vm._e()]), _c('b-tab', {
    attrs: {
      "title": "In-Store"
    }
  }, [_c('button', {
    staticClass: "btn mb-4 margin-create-button",
    on: {
      "click": _vm.startOrder
    }
  }, [_vm._v(" Create Instore Order ")]), _c('div', {
    staticClass: "d-flex justify-content-between mb-1 mt-1"
  }, [_c('b-list-group', {
    staticClass: "list-group-order"
  }, [_vm.$options.components['profile-order'] ? _vm._l(_vm.InStoreOrderList, function (item, index) {
    return _c('profile-order', {
      key: index,
      attrs: {
        "orderItem": item
      }
    });
  }) : _vm._e()], 2)], 1), !_vm.InStoreOrderList.length ? _c('div', [_c('b-img', {
    staticClass: "mr-3 w-50",
    attrs: {
      "center": "",
      "src": "instore.PNG"
    }
  }), _c('h5', {
    staticClass: "text-center mt-5",
    attrs: {
      "head-variant": "light"
    }
  }, [_vm._v(" You Have NO Instore Orders! ")]), _c('h6', {
    staticClass: "text-center color mt-5",
    attrs: {
      "head-variant": "light"
    }
  }, [_vm._v(" Looks like You haven't created any In Store Orders Yet ")]), _c('b-col', {
    staticClass: "text-center pb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary btn-lg",
    on: {
      "click": _vm.startOrder
    }
  }, [_vm._v(" Add Order ")])])], 1) : _vm._e()])], 1)], 1)])], 1) : _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-center login-signup-myaccout"
  }, [_c('h4', {
    staticClass: "mb-4"
  }, [_vm._v("Sign in to view and track you previous orders")]), _c('button', {
    staticClass: "btn btn-primary mb-4",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/login');
      }
    }
  }, [_vm._v(" Login / Signup ")])])])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }